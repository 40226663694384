jQuery(document).ready(function($) {

    $( '.state-select' ).on('click', function(e) {

        e.preventDefault();

        let citySelected  = $(this).data('city');
        let stateSelected = $(this).data('state');

        $.ajax({

            type: 'POST',
            url: wp_vars.ajaxurl,

            data: {
                action: 'select_city_ajax',
                synCity: citySelected,
                synState: stateSelected,
            },

            success: function( response ) {
                window.location.reload();
            },
        })
    })

})
